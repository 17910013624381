.st0 {
  fill: #8A171A;
}

.st1 {
  fill: none;
}

.st2 {
  font-family: 'Open Sans'; font-weight: 400;
}

.st3 {
  font-size: 102px;
}

.st4 {
 fill: #8A171A;
 font-family: 'Open Sans'; font-weight: 400;
 font-size: 102px;
}
